import React, { useState } from 'react';
import './App.css';
import { useMediaQuery } from 'react-responsive';
import ReactPlayer from 'react-player';
function App() {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  });

  const isTabletOrMobile = useMediaQuery({
    query: '(max-device-width: 1224px)'
  });


  return (
    <div className="App" style={{backgroundColor:'black'}}>
      <header className="App-header">
        {isDesktopOrLaptop && (


          <ReactPlayer
            url="./videos/videoEscritorio.mp4"
            controls
            width="95%"
            height="95%"
            playing={true}
            type='./video/mp4'
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload',
                  attributes: { preload: "auto" }
                }
              }
            }}
            loop={true}
            light={true}
            playsinline={true}
            onError={(e) => console.error('Error de reproducción:', e)}
          />
        )}

        {isTabletOrMobile && (
          <ReactPlayer
            url="./videos/videoMobile.mov"
            controls
            width="100%"
            height="100%"
            playing={true}
            type='video/mp4'
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload',
                  attributes: { preload: "auto" }
                }
              }
            }}
            loop={true}
            light={true}
            playsinline={true}
            onError={(e) => console.error('Error de reproducción:', e)}
          />

        )}

      </header>
    </div>
  );
}

export default App;


